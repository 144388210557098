<template>
  <div class="container">

    <section class="hero">
      <div class="hero-body">
        <p class="title">
          Chestnut Holler Guesthouse
        </p>
        <p class="subtitle">
          Make a reservation by email <a href="mailto:reservations@chestnutholler.com">reservations@chestnutholler.com</a>.<br/>$400 + HST / week or $80 + 15% HST / night.
          <br/>
          Phone: <a href="tel://902-320-7183">902-320-7183</a>
        </p>
      </div>
    </section>
  
    <br/>
    
    <figure class="image" style="min-width: 375px; margin: auto;">
      <img class="is-rounded" src="@/assets/house.png"/>
    </figure>
    
    <br/>
    <p class="is-size-3">
      Enjoy a private room (one double bed) on the second floor of a century-old home in a small coastal village.  
      Parking, excellent WiFi, Smart TV, etc.  Second guest room available for an additional fee.
      4-pc bathroom and guest kitchenette on guest wing (second floor).
      Rooms not accessible (stairs). No food service (coffee/tea and some breakfast items provided). Air conditioning. Level 2 EV charging.  Cats on premises. Queer friendly.
    </p>
    <br/>
    <div class="availability">
      <h1 class="is-size-4">$400 + 15% HST / week or $80 + 15% HST / night</h1>
    </div>

    
    <figure class="image" style="max-width: 375px; margin: auto;">
      <img class="is-rounded" src="@/assets/grizzle.jpg"/>
    </figure>
    
    <br/>
    <p class="is-size-3">
      We have a Level 2 EV Charger (40 Amp 10 kW) that we're happy to let our guests use (up to 50 kWh).
    </p>
    <br/>

    <!--
    <figure class="image" style="max-width: 600px; margin: auto;">
      <img class="is-rounded" src="@/assets/solar.png"/>
    </figure>
    
    
    <br/>
    <p class="is-size-3">
      During the summer months, we generate more solar electricity that we use!
    </p>
    <br/>
-->


    <br/>
    <p class="is-size-3">
      <!--Reserve a room on AirBnb (<a href="https://www.airbnb.ca/rooms/2943671" target="new">Room 1</a> or <a href="https://www.airbnb.ca/rooms/19846023" target="new">Room 2</a>), -->
      Email us <a href="mailto:reservations@chestnutholler.com">reservations@chestnutholler.com</a>.
      <br/>
          Phone: <a href="tel://902-320-7183">902-320-7183</a>
    </p>
    <br/>    
    
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2891.052309794238!2d-65.562432!3d43.5637931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5683b3f00cb6d5%3A0xb5239235c249462d!2s35%20Villagedale%20Rd%2C%20Barrington%2C%20NS%20B0W%201E0!5e0!3m2!1sen!2sca!4v1656115940151!5m2!1sen!2sca" 
    width="600" height="450" style="border: solid black 2px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    <br/>
    <br/>
    <br/>
    <br/>
    <p class="is-size-5">Book on AirBnb: <a href="https://www.airbnb.ca/rooms/19846023" target="new">https://www.airbnb.ca/rooms/19846023</a></p>
    <br/>    
    <figure class="image" style="min-width: 375px; margin: auto;">
      <img class="is-rounded" src="@/assets/room2.png"/>
    </figure>
    <br/>    
    <br/>    
    <p>Second room also available (additional fee):</p>
    <br/>    
    <br/>    
    <figure class="image" style="min-width: 375px; margin: auto;">
      <img class="is-rounded" src="@/assets/room1.png"/>
    </figure>
    <br/>    
    <br/>    
    <p class="is-size-5">Nova Scotia Licensed Tourist Accommodation #2498. Canada Select: 3.5 stars. Booking.com 9.8/10.</p>
    <br/>
    <p class="is-size-5">Email: <a href="mailto:reservations@chestnutholler.com">reservations@chestnutholler.com</a></p>
    <p>Phone: <a href="tel://902-320-7183">902-320-7183</a></p>
    <br/>
    <br/>
    <p class="is-size-5">Site built by <a href="https://topsailsoftware.ca" target="new">topsailsoftware.ca</a></p>
    <br/>
    <br/>
  </div>
</template>

<style>
.availability {
  max-width: 80%;
  margin: auto;
}
.title, .subtitle, a {
  color: white;
}
</style>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
